









import { defineComponent, onMounted, PropType } from '@nuxtjs/composition-api';
import HTMLContent from '~/components/HTMLContent.vue';

export default defineComponent({
  name: 'ProductDosage',
  components: { HTMLContent },
  props: {
    dosage: {
      type: String as PropType<string>,
      default: '',
    },
  },
  setup(_) {
    const setMobileHeaders = () => {
      const tableHeadersRaw = document.querySelectorAll('.rejestr-table tr th');
      const bodyElems = document.querySelectorAll('.rejestr-table tr td');
      bodyElems.forEach((elem, index) => {
        const childElem = elem.children && elem.children[0];
        const tableHeadersRawLength = tableHeadersRaw.length;
        const elemToInsert = tableHeadersRaw[index % tableHeadersRawLength]; // get header elem for every new row
        if (!childElem || !elemToInsert) return;
        const header = document.createElement('p');
        header.textContent = elemToInsert.textContent;
        header.classList.add('rejestr-header');
        childElem.before(header);
      });
    };

    onMounted(() => {
      setMobileHeaders();
    });
  },
});
