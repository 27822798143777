











import {
  defineComponent,
} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import MySfQuantitySelector from '~/components/StorefrontUi/MySfQuantitySelector/MySfQuantitySelector.vue';
import { debounce } from 'lodash-es';
import { useCartView } from '~/modules/checkout/composables/useCartView';
import { validateQtySelector } from '~/helpers/validateQtySelector';

export default defineComponent({
  name: 'MySfQuantitySelectorWrapper',
  components: {
    MySfQuantitySelector,
  },
  props: {
    min: {
      type: Number as PropType<number>,
      default: 1,
    },
    max: {
      type: Number as PropType<number>,
      default: null,
    },
    step: {
      type: Number as PropType<number>,
      default: 1,
    },
    qty: {
      type: Number as PropType<number>,
      default: 1,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { sendNotification } = useCartView();
    const debouncedHandler = debounce((newQty) => {
      const isValid = validateQtySelector({
        qty: newQty,
        min: props.min,
        max: props.max,
        step: props.step,
        notificationCallback: sendNotification,
      });

      if (isValid) {
        emit('disabled-status', false);
        emit('input', newQty);
      }
    }, 400);
    const handleInput = (newQty: string) => {
      emit('disabled-status', true);
      debouncedHandler(newQty);
    };

    return {
      handleInput,
    };
  },
});
