






















import {
  PropType,
  defineComponent,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ProductContentPromo',
  props: {
    contentBeforePromoBlock: {
      type: Array as PropType<any[]>,
      default: null,
    },
    mainPromoBlock: {
      type: Array as PropType<any[]>,
      default: null,
    },
  },
});
