















































import {
  defineComponent, PropType, computed,
} from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';
import Modal from '~/components/Modal.vue';

interface IDeliveryOption {
  title: string;
  price: string;
  imgUrl: string;
}

export default defineComponent({
  name: 'ProductSection',
  components: {
    Modal,
  },
  props: {
    isOpen: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    onClose: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup() {
    const deliveryOptions = computed<IDeliveryOption[]>(() => [
      {
        title: 'Kurier Fedex',
        price: '15,50 zł',
        imgUrl: addBasePath('/footer/fedex.svg'),
      },
      {
        title: 'Kurier DPD',
        price: '15 zł',
        imgUrl: addBasePath('/footer/dpd.svg'),
      },
      {
        title: 'Inpost kurier',
        price: '15,50 zł',
        imgUrl: addBasePath('/footer/inpost.svg'),
      },
      {
        title: 'Inpost paczkomat',
        price: '13 zł',
        imgUrl: addBasePath('/footer/inpost.svg'),
      },
      {
        title: 'Poczta Polska',
        price: '15,50 zł',
        imgUrl: addBasePath('/footer/pocztapolska.svg'),
      },
      {
        title: 'Raben',
        price: '15,50 zł',
        imgUrl: addBasePath('/footer/raben.svg'),
      },
    ]);

    return {
      deliveryOptions,
    };
  },
});
