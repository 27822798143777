var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.loading)?[_c('SfCarousel',{staticClass:"w-full",attrs:{"mobile-dots-pc-arrows":_vm.productsWithCommonProductCardProps.length > 4,"settings":{
        type: 'slider',
        rewind: true,
        perView: 4,
        slidePerPage: true,
        gap: 32,
        breakpoints: {
          1023: {
            perView: 2,
            gap: 8,
          },
        },
      }}},_vm._l((_vm.productsWithCommonProductCardProps),function(product,i){return _c('SfCarouselItem',{key:i + 'product'},[_c('SfProductCard',_vm._b({class:{
            'justify-between': _vm.isGift,
          },attrs:{"list":_vm.list,"position":i + 1,"add-to-cart-disabled":product.commonProps.finalPriceRaw === 0,"card-style":_vm.cardStyle,"button-text":_vm.isGift ? ("Dodaj za " + (_vm.$fc(_vm.giftProductsMap[product.sku]))) : ''},on:{"click:wishlist":function($event){return _vm.addItemToWishlist(product)},"click:add-to-cart":function($event){_vm.isGift
              ? _vm.addGiftToCartHandler(product.sku)
              :
                _vm.addItemToCart({
                  product: product,
                  quantity: product.commonProps.qtyConfig.min_sale_qty,
                  attributes: _vm.aggregations,
                })}}},'SfProductCard',product.commonProps,false))],1)}),1)]:[_c('div',{staticClass:"skeleton-grid pb-14 lg:pb-24 px-2 lg:px-0"},[_vm._l((2),function(_,index){return _c('SfProductCardSkeleton',{key:index + 'skeleton1',staticClass:"lg:hidden ",attrs:{"margin":"0","width":"100%","card-style":_vm.cardStyle}})}),_vm._v(" "),_vm._l((4),function(_,index){return _c('SfProductCardSkeleton',{key:index + 'skeleton2',staticClass:"hidden lg:block",attrs:{"margin":"0","width":"100%","card-style":_vm.cardStyle}})})],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }