import { readonly, ref } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import useApi from '~/composables/useApi';
import amPromoGetAvailableFreeGiftsGql from '~/modules/GraphQL/customQueries/amFreeGifts/amPromoGetAvailableFreeGifts.gql';
import amPromoAddFreeGiftsToCartMutationGql from '~/modules/GraphQL/customQueries/amFreeGifts/amPromoAddFreeGiftsToCartMutation.gql';
import useCart from '~/modules/checkout/composables/useCart';
import { getAmastyFreeGiftsItems } from './helpers';

export function useAmastyFreeGifts(): any {
  const { load: loadCart } = useCart();
  const { query } = useApi();
  const loading = ref(false);
  const result = ref<object>([]);
  const error = ref(null);

  const search = async (cartId: string) => {
    loading.value = true;
    try {
      const params = {
        cartId,
      };

      const res = await query(amPromoGetAvailableFreeGiftsGql, params);
      const promoItems = getAmastyFreeGiftsItems(res);
      result.value = promoItems;
    } catch (err) {
      error.value = err;
      Logger.error('useAmastyFreeGifts/search', err);
    }
    loading.value = false;
  };

  const addGiftToCart = async (cartId: string, sku: string) => {
    loading.value = true;
    try {
      const params = {
        cartId,
        promoItems: [{
          sku,
          quantity: 1,
        }],
      };

      await query(amPromoAddFreeGiftsToCartMutationGql, params);
      await loadCart();
    } catch (err) {
      error.value = err;
      Logger.error('useAmastyFreeGifts/addGiftToCart', err);
    }
    loading.value = false;
  };

  return {
    addGiftToCart,
    search,
    result,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export default useAmastyFreeGifts;
