




import {
  defineComponent, onMounted, useRouter, PropType,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ProductTrustmateReviews',
  props: {
    productSKU: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup({ productSKU }: { productSKU: string }) {
    const router = useRouter();
    const loadScriptOnLoad = () => {
      if (router.currentRoute.query.no_script) return;
      const temp = document.createElement('script');
      temp.setAttribute('src', `https://trustmate.io/widget/api/d250077e-4792-4c3f-aad8-31e726709b5a/script?product=${productSKU}`);
      document.head.append(temp);
    };
    onMounted(() => {
      loadScriptOnLoad();
    });
  },
});
