import { Logger } from './logger';

export function getUserComKey() {
  if (process.env.VSF_NUXT_APP_ENV === 'development') return process.env.VSF_DEV_USERCOM_ID;
  if (document) {
    const word = '__ca__chat';
    const caChatId = document.cookie.match(new RegExp(`${word}\\=([^;]+?);`));
    if (caChatId) {
      return caChatId[1];
    }
  }
  if ((window as any).civchat && (window as any).civchat.userKey) {
    return (window as any).civchat.userKey;
  }
  return '';
}

const getNRandomElements = (array: string[], n: number): string[] => {
  // Shuffle array
  const shuffled = array.sort(() => 0.5 - Math.random());

  return shuffled.slice(0, n);
};

export const getUserComPrediction = async (usercomURL: string, segmentId?: number) => {
  const recommendedProductSkus: string[] = [];
  try {
    const userKey = getUserComKey();
    if (!userKey) {
      Logger.error('Cannot get userCom key');
      return [];
    }
    const response = await fetch(`${usercomURL}/predictions/predict-for-user/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        user_key: userKey,
        limit: 25,
        extra_data: 0,
        segment_id: segmentId || 61,
      }),
    });
    const data = await response.json();
    if (data && data.recommended_products) {
      data.recommended_products.forEach((recommendedProduct) => {
        const itemGroupIdObj = recommendedProduct.attributes && recommendedProduct.attributes.find((attr) => attr.name === 'item_group_id');
        const productSku = itemGroupIdObj?.value
          ? itemGroupIdObj.value
          : recommendedProduct.custom_id;
        if (typeof productSku === 'string') {
          recommendedProductSkus.push(productSku);
        }
      });
    }
  } catch (e) {
    console.log(`Cannot get userCom predictions${e}`);
  }
  const randomProductSkus = getNRandomElements(recommendedProductSkus, 8);
  return [...new Set(randomProductSkus)];
};
