



















































import {
  defineComponent,
} from '@nuxtjs/composition-api';
import { PropType } from 'vue';
import SfModal from '~/components/StorefrontUi/SfModal/SfModal.vue';
import { addBasePath } from '~/helpers/addBasePath';

export default defineComponent({
  name: 'Modal',
  components: {
    SfModal,
  },
  props: {
    title: {
      type: String as PropType<string>,
      default: '',
    },
    isOpen: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    onClose: {
      type: Function as PropType<() => void>,
      required: false,
      default: () => {},
    },
    size: {
      type: String as PropType<'medium' | 'small' | 'large' | 'extralarge'>,
      required: false,
      default: 'large',
    },
    fullScreenOnMobile: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    noHeader: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    noMaxHeight: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props) {
    const close = () => {
      props.onClose();
    };

    return {
      close,
      addBasePath,
    };
  },
});
