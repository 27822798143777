








































































import SfCarousel from '~/components/StorefrontUi/SfCarousel.vue';
import SfProductCard from '~/components/StorefrontUi/SfProductCard.vue';
import {
  defineComponent, toRefs, PropType, computed, useContext,
} from '@nuxtjs/composition-api';

import useWishlist from '~/modules/wishlist/composables/useWishlist';

import { useAddToCart } from '~/helpers/cart/addToCart';
import { useAmastyFreeGifts } from '~/modules/checkout/composables/useAmastyFreeGifts';

import { useProductsWithCommonProductCardProps } from '~/modules/catalog/category/components/views/useProductsWithCommonCardProps';
import type { Product } from '~/modules/catalog/product/types';
import type { Aggregation } from '@vue-storefront/magento-types';
import SfProductCardSkeleton from '~/components/StorefrontUi/SfProductCardSkeleton.vue';
import type { ProductStrategy } from '~/modules/catalog/product/components/ProductSliderSection.vue';

export default defineComponent({
  name: 'ProductSlider',
  components: {
    SfProductCardSkeleton,
    SfProductCard,
    SfCarousel,
  },
  props: {
    products: {
      type: Array as PropType<Product[]>,
      required: true,
    },
    aggregations: {
      type: Array as PropType<Aggregation[]>,
      required: true,
    },
    loading: Boolean,
    list: {
      type: String as PropType<string>,
      default: '',
    },
    productStrategy: {
      type: String as PropType<ProductStrategy>,
      default: 'product',
    },
    giftProductsMap: {
      type: Object as PropType<object>,
      default: () => ({}),
    },
  },
  setup(props) {
    const { addOrRemoveItem } = useWishlist();
    const { addItemToCart } = useAddToCart();
    const { products, aggregations } = toRefs(props);
    const { app } = useContext();
    const { productsWithCommonProductCardProps } = useProductsWithCommonProductCardProps({
      products,
      aggregations,
    });

    const addItemToWishlist = async (product) => {
      await addOrRemoveItem({ product, list: 'Product Slider' });
    };
    const isGift = computed(() => props.productStrategy === 'gift');
    const cardStyle = computed(() => (isGift.value ? 'compact' : ''));

    const { addGiftToCart } = useAmastyFreeGifts();
    const addGiftToCartHandler = async (sku: string) => {
      await addGiftToCart(app.$vsf.$magento.config.state.getCartId(), sku);
    };

    return {
      addItemToCart,
      addItemToWishlist,
      productsWithCommonProductCardProps,
      isGift,
      cardStyle,
      addGiftToCartHandler,
    };
  },
});
