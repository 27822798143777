

















import {
  computed,
  defineComponent, onMounted, onUnmounted, PropType, ref, toRef,
} from '@nuxtjs/composition-api';
import { scrollToElement } from '~/helpers/scrollToElement';
import { throttle } from 'lodash-es';

export interface TabNavigation {
  label: string;
  id: string;
  show: boolean;
}
export default defineComponent({
  name: 'ProductTabsNavigation',
  props: {
    tabs: {
      type: Array as PropType<TabNavigation[]>,
      default: () => [],
    },
  },
  setup(props) {
    const tabs = toRef(props, 'tabs');
    const activeIndex = ref(0);
    const filteredTabs = computed(() => tabs.value.filter((tab) => tab.show));

    const setActiveIndex = (index: number) => {
      scrollToElement(filteredTabs.value[index].id);
      activeIndex.value = index;
    };

    const handleScroll = throttle(() => {
      const tabElements = filteredTabs.value
        .map((tab) => {
          const el = document.querySelector(tab.id);
          if (!el) return Number.NEGATIVE_INFINITY;
          return el.getBoundingClientRect().y - 250;
        })
        .map((number) => (number > 0 ? Number.NEGATIVE_INFINITY : number));
      const max = Math.max(...tabElements);
      const index = tabElements.indexOf(max);
      if (index !== -1) {
        activeIndex.value = index;
      }
    }, 100);

    onMounted(
      () => {
        window.addEventListener('scroll', handleScroll);
      },
    );

    onUnmounted(
      () => {
        window.removeEventListener('scroll', handleScroll);
      },
    );
    return {
      activeIndex,
      filteredTabs,
      setActiveIndex,
      scrollToElement,
    };
  },
});
