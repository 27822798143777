




























































import {
  defineComponent, PropType, toRef,
} from '@nuxtjs/composition-api';
import type { ConfigurableProductOptions } from '~/modules/GraphQL/types';
import { Product } from '~/modules/catalog/product/types';
import productGetters, { getPrice as getSimplePrice } from '~/modules/catalog/product/getters/productGetters';
import type { Price } from '~/modules/catalog/types';
import useOmnibusPrice from '~/modules/catalog/product/composables/useOmnibusPrice';

export interface VariantPrices {
  omnibusPrice: number | string | null;
  price: Price
}

export default defineComponent({
  name: 'ProductVariants',
  props: {
    options: {
      type: Array as PropType<ConfigurableProductOptions[]>,
      required: true,
    },
    getIsVariantActive: {
      type: Function as PropType<(optionId: string, attributeId: string) => boolean>,
      required: true,
    },
    shouldOptionScroll: {
      type: Function as PropType<(optionId: string) => boolean>,
      required: true,
    },
    onChooseVariant: {
      type: Function as PropType<(optionId: string, attributeId: string) => void>,
      required: true,
    },
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
  },
  setup(props) {
    const product = toRef(props, 'product');

    const { getProductOmnibusPriceBySKU } = useOmnibusPrice();

    const getOptionsPrice = (prod: Product, optionId: number | string): VariantPrices | null => {
      const variant = prod.variants.find((i) => i.product.opisowe_szczegoly_produktu_dropdown === optionId);
      if (variant) {
        return {
          omnibusPrice: getProductOmnibusPriceBySKU(variant.product.sku),
          price: getSimplePrice(variant.product),
        };
      }
      return null;
    };

    const getPrice = (optionId: number | string) => {
      const optionsPrice = getOptionsPrice(product.value, optionId);
      if (!optionsPrice) return '';
      const { price, omnibusPrice } = optionsPrice;
      const { special, regular } = price;
      const omnibus = productGetters.isSpecialPrice(special, omnibusPrice) ? special : '';
      return omnibus ? special : regular;
    };

    return {
      getPrice,
    };
  },
});
