

































































import {
  defineComponent,
  PropType,
  ref,
  watch,
} from '@nuxtjs/composition-api';
import {
  SfLoader,
} from '@storefront-ui/vue';
import SfCarousel from '~/components/StorefrontUi/SfCarousel.vue';
import ImageWrapper from '~/components/image/ImageWrapper.vue';

export default defineComponent({
  name: 'ProductGallery',
  components: {
    SfLoader,
    SfGallery: () => import('~/components/StorefrontUi/SfGallery/SfGallery.vue'),
    SfCarousel,
    ImageWrapper,
  },
  transition: 'fade',
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    imageWidth: {
      type: [Number, String] as PropType<number | string>,
      default: null,
    },
    imageHeight: {
      type: [Number, String] as PropType<number | string>,
      default: null,
    },
    thumbWidth: {
      type: [Number, String] as PropType<number | string>,
      default: null,
    },
    thumbHeight: {
      type: [Number, String] as PropType<number | string>,
      default: null,
    },
    enableZoom: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    imageTag: {
      type: String as PropType<string>,
      default: 'img',
    },
    thumbImageTag: {
      type: String as PropType<string>,
      default: 'img',
    },
    nuxtImgConfig: {
      type: Object,
      default: () => ({}),
    },
    thumbNuxtImgConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { root }) {
    const forceUpdateTrigger = ref(false);
    const forceUpdate = () => {
      forceUpdateTrigger.value = true;
      root.$nextTick(() => {
        forceUpdateTrigger.value = false;
      });
    };
    watch(() => props.images, () => {
      forceUpdate();
    });

    return {
      forceUpdateTrigger,
    };
  },
});
