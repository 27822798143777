interface CalculateDiscountPercentage {
  discountedPrice: string | number;
  originalPrice: string | number;
}

export const calculateDiscountPercentage = ({ discountedPrice, originalPrice }: CalculateDiscountPercentage): number => {
  const discounted = typeof discountedPrice === 'string' ? Number.parseFloat(discountedPrice) : discountedPrice;
  const original = typeof originalPrice === 'string' ? Number.parseFloat(originalPrice) : originalPrice;
  // Check for division by 0
  if (original === 0) {
    return 0;
  }
  return Math.max(1, Math.floor(((original - discounted) / original) * 100));
};
