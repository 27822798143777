
































import {
  defineComponent, watch,
} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import {
  SfInput,
  SfButton,
} from '@storefront-ui/vue';
import { useCounter } from '~/composables';

export default defineComponent({
  name: 'MySfQuantitySelector',
  components: {
    SfInput,
    SfButton,
  },
  props: {
    min: {
      type: Number as PropType<number>,
      default: 1,
    },
    max: {
      type: Number as PropType<number>,
      default: null,
    },
    step: {
      type: Number as PropType<number>,
      default: 1,
    },
    qty: {
      type: Number as PropType<number>,
      default: 1,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    ariaLabel: {
      type: String as PropType<string>,
      default: 'Quantity selector',
    },
  },
  setup(props, { emit }) {
    const {
      count, inc, dec, set,
    } = useCounter(props.qty, { min: props.min, max: props.max });
    const handleInc = () => { inc(props.step); emit('input', count.value); };
    const handleDec = () => { dec(props.step); emit('input', count.value); };

    const handleOnChange = (value: string) => {
      const nextValue = Number.parseFloat(value || '0');

      set(Math.min(nextValue, props.max));
      emit('input', count.value);
    };

    watch(() => props.qty, (value: number) => {
      set(value);
    });

    return {
      handleDec,
      handleInc,
      count,
      handleOnChange,
    };
  },
});
