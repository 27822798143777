























import {
  defineComponent, ref, onMounted, PropType, watch, toRef,
} from '@nuxtjs/composition-api';
import { useProduct } from '~/composables';
import productGetters, { getConcatenatedVariantsLabels } from '~/modules/catalog/product/getters/productGetters';
import { SortEnum } from '~/modules/GraphQL/types';
import { useUser } from '~/modules/customer/composables/useUser';
import { useCategorySearch } from '~/modules/catalog/category/composables/useCategorySearch';
import { addBasePath } from '~/helpers/addBasePath';
import type { Product } from '~/modules/catalog/product/types';
import { useOmnibusPrice } from '~/modules/catalog/product/composables/useOmnibusPrice';
import ProductSlider from '~/modules/catalog/product/components/ProductSlider.vue';

export type ProductStrategy = 'product' | 'gift';

export default defineComponent({
  name: 'ProductSliderSection',
  components: {
    ProductSlider,
  },
  props: {
    productsSKU: {
      type: Array as PropType<string[]>,
      required: true,
    },
    title: {
      type: String as PropType<string>,
      default: '',
    },
    list: {
      type: String as PropType<string>,
      default: '',
    },
    productStrategy: {
      type: String as PropType<ProductStrategy>,
      default: 'product',
    },
    giftProductsMap: {
      type: Object as PropType<object>,
      default: () => ({}),
    },
  },
  setup(props) {
    const productsSKU = toRef(props, 'productsSKU');
    const { loading, getProductPath, getProductListPreview } = useProduct();
    const { isAuthenticated } = useUser();
    const { search, result, loading: searchedCategoriesLoading } = useCategorySearch();
    const products = ref([]);
    const aggregations = ref([]);
    const { getOmnibusPrices } = useOmnibusPrice();
    const activeCategory = ref(null);
    const productFetchLoading = ref(false);

    const getProductForCategoryUid = async (categoryUid) => {
      productFetchLoading.value = true;
      if (!categoryUid) return;
      const productList = await getProductListPreview({
        pageSize: 8,
        currentPage: 1,
        sort: {
          position: SortEnum.Asc,
        },
        filter: {
          sku: {
            in: [...productsSKU.value],
          },
        },
      });
      if (productList?.aggregations?.length) {
        aggregations.value = productList.aggregations;
      }
      if (productList?.items?.length) {
        products.value = productList.items;
      }
      await getOmnibusPrices(products.value as Product[], 'cheapest');
      productFetchLoading.value = false;
    };

    onMounted(async () => {
      await search({
        filters: {
          parent_id: {
            eq: '122', // 122 is the id of the main category
          },
        },
      });
      activeCategory.value = result.value?.[0];

      await getProductForCategoryUid(activeCategory.value.uid);
    });

    watch(productsSKU, async (newSkusList, oldSkusList) => {
      const newStringifiedSkus = newSkusList.join(',');
      const oldStringifiedSkus = oldSkusList.join(',');
      if (newStringifiedSkus === oldStringifiedSkus) return;

      await getProductForCategoryUid(activeCategory.value.uid);
    });

    return {
      products,
      aggregations,
      activeCategory,
      addBasePath,
      loading,
      productGetters,
      getProductPath,
      isAuthenticated,
      searchedCategories: result || {},
      searchedCategoriesLoading,
      productFetchLoading,
    };
  },
  methods: { getConcatenatedVariantsLabels },
});
