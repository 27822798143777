var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.grupaUpraw.length > 0 || _vm.agrofag.length > 0 || _vm.activeSubstance.length > 0 || _vm.purpose.length > 0)?_c('ul',{staticClass:"product-info-list mt-3 md:mt-0"},[(_vm.grupaUpraw.length > 0)?_c('li',{staticClass:"product-info-list__item"},[_c('product-dropdown',{attrs:{"title":"GRUPA UPRAW"}},[_c('div',{staticClass:"flex flex-wrap"},_vm._l((_vm.grupaUpraw),function(grupa,index){return _c('nuxt-link',{key:grupa.label,staticClass:"text-primary w-fit",class:{
            'mr-1': index < _vm.grupaUpraw.length - 1,
          },attrs:{"to":grupa.link}},[_vm._v("\n          "+_vm._s(grupa.label)+_vm._s(index < _vm.grupaUpraw.length - 1 ? ',' : '.')+"\n        ")])}),1)])],1):_vm._e(),_vm._v(" "),(_vm.agrofag.length > 0)?_c('li',{staticClass:"product-info-list__item"},[_c('product-dropdown',{attrs:{"title":"AGROFAG"}},[_c('div',{staticClass:"flex flex-wrap"},_vm._l((_vm.agrofag),function(agro,index){return _c('nuxt-link',{key:agro.label,staticClass:"text-primary w-fit",class:{
            'mr-1': index < _vm.agrofag.length - 1,
          },attrs:{"to":agro.link}},[_vm._v("\n          "+_vm._s(agro.label)+_vm._s(index < _vm.agrofag.length - 1 ? ',' : '.')+"\n        ")])}),1)])],1):_vm._e(),_vm._v(" "),(_vm.activeSubstance.length > 0)?_c('li',{staticClass:"product-info-list__item"},[_c('product-dropdown',{attrs:{"title":"SUBSTANCJA CZYNNA"}},[_c('div',{staticClass:"flex flex-wrap"},_vm._l((_vm.activeSubstance),function(substance,index){return _c('nuxt-link',{key:substance.label,staticClass:"text-primary w-fit",class:{
            'mr-1': index < _vm.activeSubstance.length - 1,
          },attrs:{"to":substance.link}},[_vm._v("\n          "+_vm._s(substance.label)+_vm._s(index < _vm.activeSubstance.length - 1 ? ',' : '.')+"\n        ")])}),1)])],1):_vm._e(),_vm._v(" "),(_vm.purpose.length > 0)?_c('li',{staticClass:"product-info-list__item"},[_c('product-dropdown',{attrs:{"title":"PRZEZNACZENIE"}},[_c('div',{staticClass:"flex flex-wrap"},_vm._l((_vm.purpose),function(purpo,index){return _c('nuxt-link',{key:purpo.label,staticClass:"text-primary w-fit",class:{
            'mr-1': index < _vm.purpose.length - 1,
          },attrs:{"to":purpo.link}},[_vm._v("\n          "+_vm._s(purpo.label.charAt(0).toUpperCase() + purpo.label.slice(1))+_vm._s(index < _vm.purpose.length - 1 ? ',' : '.')+"\n        ")])}),1)])],1):_vm._e(),_vm._v(" "),(_vm.compositionOfFertilizer.length > 0)?_c('li',{staticClass:"product-info-list__item"},[_c('product-dropdown',{attrs:{"title":"SKŁAD NAWOZU"}},[_c('div',{staticClass:"flex flex-wrap"},_vm._l((_vm.compositionOfFertilizer),function(ref,index){
          var label = ref.label;
return _c('p',{key:label,staticClass:"text-primary w-fit",class:{
            'mr-1': index < _vm.compositionOfFertilizer.length - 1,
          }},[_vm._v("\n          "+_vm._s(label.charAt(0).toUpperCase() + label.slice(1))+_vm._s(index < _vm.compositionOfFertilizer.length - 1 ? ',' : '.')+"\n        ")])}),0)])],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }