//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
// TODO: change imports to custom components after merging branch refactor/remove-vsf-ui
import {
  SfIcon, SfButton, SfBar, SfOverlay,
} from '@storefront-ui/vue';
import { isClient } from '~/helpers/check-environment';

export default {
  name: 'SfModal',
  components: {
    SfBar,
    SfOverlay,
    SfIcon,
    SfButton,
  },
  model: {
    prop: 'visible',
    event: 'close',
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    cross: {
      type: Boolean,
      default: true,
    },
    overlay: {
      type: Boolean,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    transitionOverlay: {
      type: String,
      default: 'sf-fade',
    },
    transitionModal: {
      type: String,
      default: 'sf-fade',
    },
  },
  data() {
    return {
      staticClass: null,
      className: null,
    };
  },
  watch: {
    visible: {
      handler(value) {
        if (!isClient) return;
        if (value) {
          this.$nextTick(() => {
            disableBodyScroll(this.$refs.content);
          });
          document.addEventListener('keydown', this.keydownHandler);
        } else {
          clearAllBodyScrollLocks();
          document.removeEventListener('keydown', this.keydownHandler);
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    clearAllBodyScrollLocks();
  },
  methods: {
    close() {
      this.$emit('close', false);
    },
    checkPersistence() {
      if (!this.persistent) {
        this.close();
      }
    },
    keydownHandler(e) {
      if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        this.close();
      }
    },
    classHandler() {
      if (this.staticClass !== this.$vnode.data.staticClass) {
        this.staticClass = this.$vnode.data.staticClass;
      }
      if (this.className !== this.$vnode.data.class) {
        this.className = this.$vnode.data.class;
      }
    },
  },
};
