

















































import {
  computed,
  defineComponent, PropType, toRefs,
} from '@nuxtjs/composition-api';
import Modal from '~/components/Modal.vue';
import { addBasePath } from '~/helpers/addBasePath';
import { useImage } from '~/composables';
import ImageWrapper from '~/components/image/ImageWrapper.vue';

export default defineComponent({
  name: 'ProductPaymentMethodsPopup',
  components: {
    Modal,
    ImageWrapper,
  },
  props: {
    isOpen: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    onClose: {
      type: Function as PropType<() => void>,
      required: true,
    },
    productTitle: {
      type: String as PropType<string>,
      default: '',
    },
    productImage: {
      type: Object as PropType<{
        alt?: string;
        mobile: {
          url?: string;
        };
      }>,
      default: () => {},
    },
  },
  setup(props) {
    const { productImage } = toRefs(props);
    const { imageSizes } = useImage();
    const isExistProductImage = computed(() => !!productImage.value?.mobile?.url);

    return {
      imageSizes,
      addBasePath,
      isExistProductImage,
    };
  },
});
