export const getAmastyFreeGiftsItems = (response: any) => {
  const items = response?.data?.amPromoGetAvailableFreeGifts?.promo_items;
  if (!items) return {};

  const itemsMap = items.reduce((map, item: any) => {
    if (item.available_qty !== 0) {
      // eslint-disable-next-line no-param-reassign
      map[item.sku] = item.price;
    }
    return map;
  }, {});

  return itemsMap;
};
