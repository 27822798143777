interface IQtySelectorParams {
  qty: number;
  step: number;
  min: number;
  max: number;
  notificationCallback: (notification: any) => void;
}
export function validateQtySelector({
  qty, step, min, max, notificationCallback,
}: IQtySelectorParams): boolean {
  if (qty < min) {
    notificationCallback({
      id: Symbol('product-update-qty-success'),
      message: `Najmniej, ile możesz zakupić to ${min}.`,
      type: 'success',
      icon: 'check',
      persist: false,
      title: 'Cart',
    });
    return false;
  }
  if (qty > max) {
    notificationCallback({
      id: Symbol('product-update-qty-success'),
      message: `Najwięcej, ile możesz zakupić to ${max}.`,
      type: 'success',
      icon: 'check',
      persist: false,
      title: 'Cart',
    });
    return false;
  }
  if (qty % step !== 0) {
    notificationCallback({
      id: Symbol('product-update-qty-success'),
      message: `Możesz kupić ten produkt tylko w ilościach po ${step} na raz.`,
      type: 'success',
      icon: 'check',
      persist: false,
      title: 'Cart',
    });
    return false;
  }

  return true;
}
