import {
  ref, Ref, watch, computed, ComputedRef,
} from '@nuxtjs/composition-api';
import { Product } from '~/modules/catalog/product/types';
import {
  getActiveSubstance,
  getAgrofag,
  getAttributeLabel,
  getAvailableType, getCompositionOfFertilizer, getCrossSellProducts,
  getDeliveryDate,
  getDeliveryIn,
  getDescription, getDosage, getGrupaUpraw,
  getIsNewProduct,
  getName,
  getPrice,
  getProductAttachments,
  getProductEan, getPurpose,
  getQtyConfig, getRelatedProducts,
  getShortDescription, getSubstituteProducts, getTypeOfProtection, getUpsellProducts, isProductBestseller, isProductEko, isProductRecommended,
  isProductVatOnly,
  isSpecialPrice,
  getAdditionalFields, getIsOutOfStatus, getProductThumbnailImage, getInfoLabel,
} from '~/modules/catalog/product/getters/productGetters';
import useOmnibusPrice from '~/modules/catalog/product/composables/useOmnibusPrice';
import { Aggregation } from '~/modules/GraphQL/types';
import { useImage } from '~/composables';
import useProductGallery from '~/modules/catalog/product/composables/useProductGallery';
import { isProductEstimatedAvailability } from '~/modules/catalog/product/helpers/stockHelpers';

export function useProductHandler(product: Ref<Product>, attributeValues: Ref<Aggregation[]>) {
  const productRef = ref<Product>(product.value);
  const attributeValuesRef = ref<Aggregation[]>(attributeValues.value);

  const { getProductOmnibusPriceBySKU } = useOmnibusPrice();
  const { getMagentoImage } = useImage();
  watch(product, (newProduct) => {
    productRef.value = newProduct;
  });

  const currentProduct = computed(
    () => productRef.value?.configurable_product_options_selection_all?.variant ?? productRef.value,
  ) as ComputedRef<Product>;

  const isVariantSelected = computed(() => !!productRef.value?.configurable_product_options_selection_all?.variant);

  const { productGallery, imageSizes } = useProductGallery(productRef);

  const title = computed(() => getName(currentProduct.value));
  const qtyConfig = computed(() => getQtyConfig(currentProduct.value));
  const productDescription = computed(() => getDescription(currentProduct.value));
  const productShortDescription = computed(() => getShortDescription(currentProduct.value));

  const omnibusPrice = computed(() => getProductOmnibusPriceBySKU(currentProduct.value.sku));
  // const regularPrice = computed(() => getPrice(currentProduct.value).regular);
  // const specialPrice = computed(() => (isSpecialPrice(getPrice(currentProduct.value).special, omnibusPrice.value)
  //   ? getPrice(currentProduct.value).special : ''));

  const finalPrice = computed(() => getPrice(currentProduct.value).final);
  const currentOmnibusPrice = computed(() => (isSpecialPrice(getPrice(currentProduct.value).special, omnibusPrice.value) ? omnibusPrice.value : ''));

  const manufacturerLabel = computed(() => (currentProduct.value.manufacturer ? getAttributeLabel('manufacturer', currentProduct.value.manufacturer, attributeValuesRef.value) : ''));
  const infoLabel = computed(() => getInfoLabel(productRef.value, attributeValuesRef.value));
  const deliveryTimeLabel: Ref<string | number> = computed(() => (currentProduct.value.delivery_time ? getAttributeLabel('delivery_time', currentProduct.value.delivery_time, attributeValuesRef.value) : ''));
  const typeOfProtection = computed(() => (getTypeOfProtection(productRef.value) ? getAttributeLabel('rodzaj_srodka_ochrony', productRef.value.rodzaj_srodka_ochrony, attributeValuesRef.value) : ''));
  const available = computed(() => getAvailableType(currentProduct.value));
  const deliveryIn = computed(() => getDeliveryIn(deliveryTimeLabel.value));
  const deliveryDate = computed(() => getDeliveryDate(currentProduct.value));
  const productAttachments = computed(() => getProductAttachments(productRef.value));
  const productEan = computed(() => getProductEan(currentProduct.value));
  const isNew = computed(() => getIsNewProduct(currentProduct.value));
  const isVatOnly = computed(() => isProductVatOnly(currentProduct.value));
  const isRecommended = computed(() => isProductRecommended(currentProduct.value));
  const isBestseller = computed(() => isProductBestseller(currentProduct.value));
  const isEko = computed(() => isProductEko(productRef.value));
  const isEstimatedAvailability = computed(() => isProductEstimatedAvailability(productRef.value));
  const agrofag = computed(() => (getAgrofag(currentProduct.value).length > 0
    ? getAgrofag(currentProduct.value)
      .map((i) => ({
        label: getAttributeLabel('agrofag', i, attributeValuesRef.value),
        link: `/sklep?agrofag=${i}`,
      }))
    : []));
  const activeSubstance = computed(() => (getActiveSubstance(currentProduct.value).length > 0
    ? getActiveSubstance(currentProduct.value)
      .map((i) => ({
        label: getAttributeLabel('active_substance', i, attributeValuesRef.value),
        link: `/sklep?active_substance=${i}`,
      }))
    : []));
  const purpose = computed(() => (getPurpose(currentProduct.value).length > 0
    ? getPurpose(currentProduct.value)
      .map((i) => ({
        label: getAttributeLabel('purpose', i, attributeValuesRef.value),
        link: `/sklep?purpose=${i}`,
      }))
    : []));
  const grupaUpraw = computed(() => (getGrupaUpraw(currentProduct.value).length > 0
    ? getGrupaUpraw(currentProduct.value)
      .map((i) => ({
        label: getAttributeLabel('grupa_upraw', i, attributeValuesRef.value),
        link: `/sklep?grupa_upraw=${i}`,
      }))
    : []));
  const compositionOfFertilizer = computed(() => getCompositionOfFertilizer(currentProduct.value, attributeValuesRef.value));
  const dosage = computed(() => getDosage(productRef.value));
  const crosssellProductsSKUs = computed(() => getCrossSellProducts(productRef.value));
  const upsellProductsSKUs = computed(() => getUpsellProducts(productRef.value));
  const relatedProductsSKUs = computed(() => getRelatedProducts(productRef.value));
  const substituteProductsSKUs = computed(() => getSubstituteProducts(productRef.value));
  const productAdditionalFields = computed(() => getAdditionalFields(currentProduct.value, attributeValuesRef.value));

  const isOrderNumber = computed(() => currentProduct.value?.order_number);
  const isAvailableInStock = computed(() => available.value.stock_status === 'IN_STOCK');
  const isOutOfStatus = computed(() => getIsOutOfStatus(productRef.value));
  // @ts-ignore
  const bundleProductItems = computed(() => currentProduct.value?.items);

  const thumbnailImage = computed(() => getMagentoImage(getProductThumbnailImage(currentProduct.value)));
  const sortedProductGallery = computed(() => productGallery.value.sort((a, b) => {
    if (a.big.url === thumbnailImage.value) return -1;
    if (b.big.url === thumbnailImage.value) return 1;
    return 0;
  }));

  return {
    currentProduct,
    title,
    qtyConfig,
    isVariantSelected,
    productDescription,
    productShortDescription,
    finalPrice,
    currentOmnibusPrice,
    manufacturerLabel,
    deliveryTimeLabel,
    available,
    deliveryIn,
    deliveryDate,
    productAttachments,
    productEan,
    isNew,
    isVatOnly,
    isRecommended,
    isBestseller,
    isEko,
    agrofag,
    activeSubstance,
    purpose,
    grupaUpraw,
    dosage,
    crosssellProductsSKUs,
    upsellProductsSKUs,
    relatedProductsSKUs,
    substituteProductsSKUs,
    isOrderNumber,
    isAvailableInStock,
    bundleProductItems,
    typeOfProtection,
    compositionOfFertilizer,
    productAdditionalFields,
    isOutOfStatus,
    productGallery,
    imageSizes,
    sortedProductGallery,
    isEstimatedAvailability,
    infoLabel,
  };
}
