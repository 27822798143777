var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full relative"},[_c('div',{staticClass:"absolute top-0 left-0 flex p-2 lg:px-5 z-[1] top-bar"},[_vm._t("top")],2),_vm._v(" "),_c('div',{staticClass:"gallery"},[_c('SfLoader',{staticClass:"loading--product-gallery",attrs:{"loading":_vm.loading}},[_c('SfGallery',{attrs:{"images":_vm.images,"image-width":_vm.imageWidth,"image-height":_vm.imageHeight,"thumb-width":_vm.thumbWidth,"thumb-height":_vm.thumbHeight,"enable-zoom":_vm.enableZoom,"image-tag":_vm.imageTag,"thumb-image-tag":_vm.thumbImageTag,"nuxt-img-config":_vm.nuxtImgConfig,"thumb-nuxt-img-config":_vm.thumbNuxtImgConfig},scopedSlots:_vm._u([{key:"big-image-bottom",fn:function(){return [_vm._t("bottom")]},proxy:true}],null,true)})],1)],1),_vm._v(" "),_c('div',{staticClass:"carousel"},[(!_vm.forceUpdateTrigger)?_c('SfCarousel',{staticClass:"container",attrs:{"dots":true,"settings":{
        type: 'slider',
        perView: 1,
        gap: 0,
        breakpoints: {
          768: {
            perView: 1,
          },
        },
      }}},_vm._l((_vm.images),function(image,index){return _c('SfCarouselItem',{key:index + 'mobile'},[_c('ImageWrapper',{staticClass:"carousel-img",attrs:{"src":image.desktop.url,"alt":image.alt,"image-tag":_vm.imageTag,"nuxt-img-config":_vm.nuxtImgConfig,"loading":index > 0 ? 'lazy' : 'eager'}})],1)}),1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"absolute flex justify-end p-4 z-[1] bottom-bar"},[_vm._t("bottom")],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }