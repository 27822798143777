














































import {
  computed, defineComponent, PropType, ref, toRefs, useRoute,
} from '@nuxtjs/composition-api';
import { useUiNotification } from '~/composables';
import useUser from '~/modules/customer/composables/useUser';
import type { Product } from '~/modules/catalog/product/types';
import SfButton from '~/components/StorefrontUi/SfButton.vue';

export default defineComponent({
  name: 'ProductCustomerNotification',
  components: {
    SfButton,
  },
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
  },
  setup(props) {
    const {
      product,
    } = toRefs(props);
    const { send: sendNotification } = useUiNotification();
    const { isAuthenticated, saveCustomerNotify, loading } = useUser();
    const isSavedCustomerNotify = ref([]);
    const route = useRoute();
    const encodedFullPath = computed(() => encodeURIComponent(route.value.fullPath));

    const currentProductId = computed(
      () => `${product.value?.configurable_product_options_selection_all?.variant?.id ?? product.value?.id}`,
    );
    const isCustomerSubscribedCurrentProduct = computed(() => isSavedCustomerNotify.value.includes(currentProductId.value));
    const saveCustomerToNotification = async () => {
      const response = await saveCustomerNotify(`${currentProductId.value}`);
      if (response === null) {
        sendNotification({
          id: Symbol('product_added_to_notification_error'),
          message: 'Wystąpił błąd podczas zapisywania alertu. Spróbuj ponownie.',
          type: 'warning',
          icon: 'alert',
          persist: false,
          title: 'User Account',
        });
      }
      if (response?.product_id) {
        isSavedCustomerNotify.value = [...isSavedCustomerNotify.value, `${response?.product_id}`];
        sendNotification({
          id: Symbol('product_added_to_notification'),
          message: 'Subskrypcja alertu została zapisana.',
          type: 'info',
          icon: 'alert',
          persist: false,
          title: 'User Account',
        });
      }
    };

    return {
      loading,
      isAuthenticated,
      encodedFullPath,
      saveCustomerToNotification,
      isCustomerSubscribedCurrentProduct,
    };
  },
});
