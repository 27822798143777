import { render, staticRenderFns } from "./ProductsContentBanners.vue?vue&type=template&id=c89ff108&"
import script from "./ProductsContentBanners.vue?vue&type=script&lang=ts&"
export * from "./ProductsContentBanners.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports