
































import {
  defineComponent, onMounted, onUnmounted, ref,
} from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import { useUiState } from '~/composables';
import { useProductModalStore } from '~/stores/productModal';
import { storeToRefs } from 'pinia';
import { throttle } from 'lodash-es';

export default defineComponent({
  name: 'ProductStickyElement',
  components: {
    LazyHydrate,
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
  },
  transition: 'fade',
  props: {
    isMobileVariantsOpen: {
      type: Boolean,
      default: false,
    },
    isConfigurable: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit, root }) {
    const { isProductStickyVariantOpen, isWishlistSidebarOpen, isCartSidebarOpen } = useUiState();
    const { toggleProductStickyVariant } = useUiState();
    const showSticky = ref(false);
    const isPWA = root.$isPWA;

    const productModalStore = useProductModalStore();
    const { isModalOpen } = storeToRefs(productModalStore);

    const handleScroll = throttle(() => {
      const sectionRefEl = document.querySelector('#addToCart');
      if (!sectionRefEl) return;
      const { top } = sectionRefEl.getBoundingClientRect();
      showSticky.value = top <= -100;
    }, 100);

    const handleOverlayClick = () => {
      emit('overlay-click', false);
    };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
      toggleProductStickyVariant();
    });
    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
      toggleProductStickyVariant();
    });
    return {
      handleOverlayClick,
      isProductStickyVariantOpen,
      isWishlistSidebarOpen,
      isCartSidebarOpen,
      isModalOpen,
      showSticky,
      isPWA,
    };
  },
});
