










import {
  PropType,
  defineComponent,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ProductContentBanners',
  props: {
    banners: {
      type: Array as PropType<any[]>,
      default: null,
    },
  },
});
