













import { computed, defineComponent } from '@nuxtjs/composition-api';
import ProductSliderSection from '~/modules/catalog/product/components/ProductSliderSection.vue';

interface IProductID {
  _uid: string;
  id: string;
}

export default defineComponent({
  name: 'ProductSlider',
  components: { ProductSliderSection },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { blok: { ProductsIds, sliderName } } = props;
    const isProductsIds = computed(() => ProductsIds.length > 0);
    const productsIds = computed(() => isProductsIds.value && ProductsIds.map((product: IProductID) => product.id));
    return {
      sliderName,
      productsIds,
      isProductsIds,
    };
  },
});
