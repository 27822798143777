export default `
query amPromoGetAvailableFreeGifts($cartId: String!) {
  amPromoGetAvailableFreeGifts(cartId: $cartId) {\
    available_gifts_qty
    promo_items {
      sku
      available_qty
      price
    }
  }
}
`;
