




























import {
  defineComponent, PropType,
} from '@nuxtjs/composition-api';

import HTMLContent from '~/components/HTMLContent.vue';

export default defineComponent({
  name: 'ProductContent',
  components: { HTMLContent },
  props: {
    productDescription: {
      type: String as PropType<string>,
      default: '',
    },
    content: {
      type: Object as PropType<any>,
      default: null,
    },
    banners: {
      type: Array as PropType<any[]>,
      default: null,
    },
    contentBeforePromoBlock: {
      type: Array as PropType<any[]>,
      default: null,
    },
    mainPromoBlock: {
      type: Array as PropType<any[]>,
      default: null,
    },
    isNewContent: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    oldDescription: {
      type: String as PropType<string>,
      default: null,
    },
    isEmptyOldDescription: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
});
