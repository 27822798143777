import { Product } from '~/modules/catalog/product/types';

export const getBundleProductLinksSkus = (product: Product) => {
  if (!product.product_links || product.product_links.length === 0) {
    return [];
  }
  return product.product_links.filter(
    (link) => link.link_type === 'crosssell' && link.linked_product_type === 'bundle',
  ).map((link) => link.linked_product_sku);
};
