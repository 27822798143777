













































































































































































































































































































import {
  computed, defineComponent, PropType, ref, toRefs,
  watch,
} from '@nuxtjs/composition-api';

import type { Product } from '~/modules/catalog/product/types';
import { addBasePath } from '~/helpers/addBasePath';
import { SfImage } from '@storefront-ui/vue';
import { ProductAvailable } from '~/modules/catalog/product/helpers/stockHelpers';
import { getCurrentDelivery } from '~/modules/catalog/product/helpers/deliveryHelpers';
import { calculateDiscountPercentage } from '~/modules/catalog/product/helpers/calculateDiscountPercentage';
import { scrollToElement } from '~/helpers/scrollToElement';
import ProductCustomerNotification from '~/modules/catalog/product/components/ProductCustomerNotification.vue';
import ProductDeliveryPopup from '~/modules/catalog/product/components/ProductDeliveryPopup.vue';
import ProductPaymentMethodsPopup from '~/modules/catalog/product/components/ProductPaymentMethodsPopup.vue';
import { useModals } from '~/modules/catalog/product/composables/useModals';

export default defineComponent({
  name: 'ProductsAside',
  components: {
    ProductCustomerNotification,
    ProductPrices: () => import('~/modules/catalog/product/components/ProductPrices.vue'),
    SfImage,
    ProductDeliveryPopup,
    ProductPaymentMethodsPopup,
    ProductTierRatesTable: () => import('~/modules/catalog/product/components/ProductTierRatesTable.vue'),
  },
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
    productImage: {
      type: [Object, null],
      default: null,
    },
    available: {
      type: Object as PropType<ProductAvailable>,
      default: () => ({}),
    },
    deliveryIn: {
      type: String as PropType<string>,
      default: null,
    },
    deliveryDate: {
      type: String as PropType<string>,
      default: null,
    },
    isProductEstimatedAvailability: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    finalPrice: {
      type: [Number, String] as PropType<number | string>,
      default: null,
    },
    omnibusPrice: {
      type: [Number, String] as PropType<number | string>,
      default: null,
    },
    isVariantSelected: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isSubstituteProducts: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isBundleProducts: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isOrderNumber: {
      type: [Number, String] as PropType<number | string>,
      default: null,
    },
    currentSku: {
      type: String as PropType<string>,
      default: '',
    },
  },
  setup(props) {
    const {
      product, finalPrice, omnibusPrice,
    } = toRefs(props);

    const {
      openModal, closeModals, isPaymentMethodsPopup, isDeliveryPopup,
    } = useModals();

    const {
      available, deliveryIn, deliveryDate, isProductEstimatedAvailability,
    } = props;
    const discount = computed(() => {
      if (omnibusPrice.value) {
        return calculateDiscountPercentage({ discountedPrice: finalPrice.value, originalPrice: omnibusPrice.value });
      }
      return null;
    });

    const isVatOnly = computed(() => product.value?.invoice_only);
    const currentDelivery = computed(() => getCurrentDelivery({
      deliveryIn, deliveryDate, available, isProductEstimatedAvailability,
    }));

    const isPopup = ref(false)
    watch(() => [isPaymentMethodsPopup.value, isDeliveryPopup.value], ([paymentMethodsPopup, deliveryPopup]) => {
      const isPopupBuffor = paymentMethodsPopup || deliveryPopup;
      if (!isPopupBuffor) {
        setTimeout(() => {
          isPopup.value = false;
        }, 250);
        return
      }
      isPopup.value = isPopupBuffor;
    })

    return {
      isPopup,
      addBasePath,
      discount,
      isVatOnly,
      currentDelivery,
      scrollToElement,
      openModal,
      closeModals,
      isDeliveryPopup,
      isPaymentMethodsPopup,
    };
  },
});
