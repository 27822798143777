enum AvailabilityEnum {
  IN_STOCK = 'InStock',
  PHONE_ORDER = 'PreOrder',
  WITHDRAWN = 'OutOfStock',
  OUT_OF_STOCK = 'OutOfStock',
}

interface ProductOfferInterface {
  price: string | number;
  availability: keyof typeof AvailabilityEnum;
  url: string;
  ean: string | number;
}

interface ProductSchemaInterface {
  name: string;
  description: string;
  sku: string;
  image: string;
  brandName: string;
  offer: ProductOfferInterface;
  deliveryIn: string | number;
}

export const getProductSchema = (data: ProductSchemaInterface) => JSON.stringify({
  '@context': 'https://schema.org/',
  '@type': 'Product',
  name: data.name,
  description: data.description,
  sku: data.sku,
  image: data.image,
  brand: {
    '@type': 'Brand',
    name: data.brandName,
  },
  offers: {
    '@type': 'Offer',
    priceCurrency: 'PLN',
    price: data.offer.price,
    availability: `http://schema.org/${AvailabilityEnum[data.offer.availability]}`,
    url: `https://agrosimex.pl${data.offer.url}`,
    gtin13: data.offer.ean,
    shippingDetails: {
      '@type': 'OfferShippingDetails',
      shippingRate: {
        '@type': 'MonetaryAmount',
        value: 0,
        currency: 'PLN',
      },
      shippingDestination: {
        '@type': 'DefinedRegion',
        addressCountry: {
          '@type': 'Country',
          name: 'PL',
        },
      },
      deliveryTime: {
        '@type': 'ShippingDeliveryTime',
        handlingTime: {
          '@type': 'QuantitativeValue',
          minValue: 0,
          maxValue: 1,
          unitCode: 'DAY',
        },
        transitTime: {
          '@type': 'QuantitativeValue',
          minValue: 0,
          maxValue: data.deliveryIn,
          unitCode: 'DAY',
        },
      },
    },
    hasMerchantReturnPolicy: {
      '@type': 'MerchantReturnPolicy',
      returnPolicyCategory: 'http://schema.org/MerchantReturnFiniteReturnWindow',
      merchantReturnDays: 14,
      returnMethod: 'http://schema.org/ReturnByMail',
      returnFees: 'http://schema.org/FreeReturn',
      applicableCountry: {
        '@type': 'Country',
        name: 'PL',
      },
    },
  },
});
