


























import { defineComponent, PropType, useContext } from '@nuxtjs/composition-api';
import { SfImage } from '@storefront-ui/vue';
import { addBasePath } from '~/helpers/addBasePath';

interface Attachment {
  title: string;
  url: string;
}

export default defineComponent({
  name: 'ProductDownloadFiles',
  components: { SfImage },
  transition: 'fade',
  props: {
    attachments: {
      type: Array as PropType<Attachment[]>,
      required: true,
    },
  },
  setup() {
    const { env } = useContext();
    const appUrl = env.VSF_STORE_URL;
    const linkToAttachment = (url: string) => {
      if (url.startsWith('http')) {
        return url;
      }
      return `${appUrl}/attachment${url}`;
    };
    return {
      appUrl,
      addBasePath,
      linkToAttachment,
    };
  },
});
