





















































































































import {
  defineComponent,
  PropType,
} from '@nuxtjs/composition-api';
import ProductDropdown from '~/modules/catalog/product/components/ProductDropdown.vue';

export default defineComponent({
  name: 'ProductAdditionalInfo',
  components: {
    ProductDropdown,
  },
  transition: 'fade',
  props: {
    grupaUpraw: {
      type: Array as PropType<{ label: any, link: string }[]>,
      default: () => [],
    },
    activeSubstance: {
      type: Array as PropType<{ label: any, link: string }[]>,
      default: () => [],
    },
    agrofag: {
      type: Array as PropType<{ label: any, link: string }[]>,
      default: () => [],
    },
    purpose: {
      type: Array as PropType<{ label: any, link: string }[]>,
      default: () => [],
    },
    compositionOfFertilizer: {
      type: Array as PropType<{ label: any, link?: string }[]>,
      default: () => [],
    },
  },
});
