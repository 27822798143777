

















































































































































































































































































































































































































































































































import { SfAddToCart, SfImage, SfLoader } from '@storefront-ui/vue';
import MySfQuantitySelector from '~/components/StorefrontUi/MySfQuantitySelector/MySfQuantitySelectorWrapper.vue';
import {
  ref,
  computed,
  useContext,
  useRoute,
  useRouter,
  defineComponent,
  PropType,
  toRef,
  watch,
  onMounted,
  useFetch,
  useMeta,
} from '@nuxtjs/composition-api';
import { useStoryblokBridge } from '@storyblok/nuxt-2';
import { ISbStoryData } from '@storyblok/js/dist/types';

import { isSpecialPrice } from '~/modules/catalog/product/getters/productGetters';
import { getProductSchema } from '~/modules/catalog/product/helpers/schemaHelpers';

import useWishlist from '~/modules/wishlist/composables/useWishlist';
import HTMLContent from '~/components/HTMLContent.vue';
import { useUser } from '~/modules/customer/composables/useUser';
import { Product } from '~/modules/catalog/product/types';
import ProductLayout from '~/modules/catalog/product/layouts/product.vue';
import ProductsAside from '~/modules/catalog/product/components/ProductsAside.vue';
import { Aggregation } from '~/modules/GraphQL/types';
import { addBasePath } from '~/helpers/addBasePath';
import SfButton from '~/components/StorefrontUi/SfButton.vue';
import ProductStickyElement from '~/modules/catalog/product/components/ProductStickyElement.vue';
import { useImage, useUiHelpers } from '~/composables';
import ProductTrustmateReviews from '~/modules/catalog/product/components/ProductTrustmateReviews.vue';
import ProductTrustmateStars from '~/modules/catalog/product/components/ProductTrustmateStars.vue';
import ProductDownloadFiles from '~/modules/catalog/product/components/ProductDownloadFiles.vue';
import ProductSection from '~/modules/catalog/product/components/ProductSection.vue';

import ProductLabels from '~/components/storyblok/ProductLabels.vue';
import ProductGallery from '~/modules/catalog/product/components/ProductGallery.vue';
import AddToWishlistHeart from '~/modules/catalog/product/components/AddToWishlistHeart.vue';
import ProductAdditionalInfo from '~/modules/catalog/product/components/ProductAdditionalInfo.vue';
import ProductVariants from '~/modules/catalog/product/components/ProductVariants.vue';
import ProductContent from '~/modules/catalog/product/components/content/ProductsContent.vue';
import ProductDosage from '~/modules/catalog/product/components/ProductDosage.vue';
import ProductSliderSection from '~/modules/catalog/product/components/ProductSliderSection.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import ProductContentBanners from '~/modules/catalog/product/components/content/ProductsContentBanners.vue';
import ProductContentPromo from '~/modules/catalog/product/components/content/ProductsContentPromo.vue';
import { useProductHandler } from '~/modules/catalog/product/composables/useProductHandler/useProductHandler';
import { useAddToCart } from '~/helpers/cart/addToCart';
import { getUserComPrediction } from '~/helpers/userComPredictions';
import { getBundleProductLinksSkus } from '~/helpers/getBundleProductLinksSkus';
import ProductTabsNavigation from '~/modules/catalog/product/components/ProductTabsNavigation.vue';
import { CacheTagPrefix, useCache } from '@vue-storefront/cache';

import { getMetaInfo, getUrlMeta, getMagentoMeta } from '~/helpers/getMetaInfo';
import useStoryblokView from '~/modules/storyblok/composables/useStoryblokView';
import { renderRichText } from '~/modules/storyblok/helpers/renderRichText';

export default defineComponent({
  name: 'ConfigurableProduct',
  components: {
    ProductTabsNavigation,
    ProductContentPromo,
    ProductContentBanners,
    LoadWhenVisible,
    ProductSliderSection,
    ProductDosage,
    ProductContent,
    ProductAdditionalInfo,
    ProductPrices: () => import('~/modules/catalog/product/components/ProductPrices.vue'),
    ProductTrustmateStars,
    ProductsNoImage: () => import('~/modules/catalog/product/components/ProductsNoImage.vue'),
    ProductsAside,
    ProductLayout,
    CheaperInBundle: () => import('~/modules/catalog/product/components/CheaperInBundle.vue'),
    ProductAdditionalFields: () => import('~/modules/catalog/product/components/ProductAdditionalFields.vue'),
    // eslint-disable-next-line vue/no-unused-components
    HTMLContent,
    SfAddToCart,
    SfImage,
    SfButton,
    ProductStickyElement,
    ProductTrustmateReviews,
    ProductSection,
    ProductDownloadFiles,
    ProductLabels,
    ProductGallery,
    AddToWishlistHeart,
    SfLoader,
    ProductVariants,
    MySfQuantitySelector,
  },
  transition: 'fade',
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
    isFetching: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    attributeValues: {
      type: Array as PropType<Aggregation[]>,
      default: () => [],
    },
  },
  setup(props, { emit, root }) {
    const product = toRef(props, 'product');
    const attributeValues = toRef(props, 'attributeValues');

    const route = useRoute();
    const router = useRouter();
    const { app, env } = useContext();

    const { isAuthenticated } = useUser();
    const { addOrRemoveItem, isInWishlist } = useWishlist();
    const {
      addItemToCart,
      loading: isCartLoading,
      canAddToCart,
    } = useAddToCart();
    const { addTags } = useCache();
    const { baseImageConfig, getMagentoImageWithConfig } = useImage();
    const { getStoryBySlug } = useStoryblokView();

    const {
      title,
      qtyConfig,
      currentProduct,
      isVariantSelected,
      productDescription,
      productShortDescription,
      finalPrice,
      currentOmnibusPrice,
      manufacturerLabel,
      deliveryTimeLabel,
      available,
      deliveryIn,
      deliveryDate,
      productAttachments,
      productEan,
      isNew,
      isVatOnly,
      isRecommended,
      isBestseller,
      isEko,
      agrofag,
      activeSubstance,
      purpose,
      grupaUpraw,
      dosage,
      upsellProductsSKUs,
      relatedProductsSKUs,
      substituteProductsSKUs,
      isOrderNumber,
      isAvailableInStock,
      typeOfProtection,
      compositionOfFertilizer,
      productAdditionalFields,
      isOutOfStatus,
      imageSizes,
      sortedProductGallery,
      isEstimatedAvailability,
      infoLabel,
    } = useProductHandler(product, attributeValues);

    const productMainImage = ref(sortedProductGallery.value[0]);
    const uiHelpers = useUiHelpers();

    const metaInfo = () => {
      const urlMeta = getUrlMeta(uiHelpers.resolvePath());
      const magentoMeta = getMagentoMeta({
        data: product.value,
        baseData: urlMeta,
        image: getMagentoImageWithConfig(sortedProductGallery.value[0]?.desktop?.url),
      });
      const meta = getMetaInfo({ page: magentoMeta });
      return meta;
    };
    // ! We have to do it here and not in product.vue because we need product on component initialization
    // ! in order to get meta info correctly
    useMeta(() => metaInfo());

    const qty = ref(qtyConfig.value.min_sale_qty);

    const getProductConfigurationsBySku = (
      sku: string,
    ): Record<string, string> => {
      const configurations: Record<string, string> = {};
      if (!product.value || !sku) return configurations;
      const selectedVariant = product.value?.variants?.find(
        (variant) => variant?.product?.sku === sku,
      );
      const configurationValueIndexList = selectedVariant?.attributes?.map(
        (attribute) => attribute?.value_index,
      );
      product.value.configurable_options?.forEach((option) => option?.values?.forEach((value) => {
        if (configurationValueIndexList?.includes(value?.value_index)) {
          configurations[option.attribute_uid] = value.uid;
        }
      }));
      return configurations;
    };

    const productConfiguration = ref(
      getProductConfigurationsBySku(route.value.query?.childSku as string),
    );

    const configurableOptions = computed(
      () => product.value?.configurable_options ?? [],
    );
    const getIsVariantActive = (
      optionId: string,
      attributeId: string,
    ): boolean => productConfiguration.value[optionId] === attributeId;

    const getBaseSearchQuery = () => ({
      filter: {
        sku: {
          eq: product.value.sku,
        },
      },
      configurations: Object.entries(productConfiguration.value).map(
        (config) => config[1],
      ) as string[],
    });

    const getProductSkuByConfigurations = (
      label: string,
      value: string,
    ): string => {
      const selectedOption = product.value.configurable_options.find(
        (option) => option.attribute_uid === label,
      );
      const selectedValue = selectedOption.values.find(
        (optionValue) => optionValue.uid === value,
      );

      const selectedVariant = product.value.variants.find((variant) => variant.attributes.some(
        (attribute) => attribute.value_index === selectedValue.value_index,
      ));
      return selectedVariant?.product?.sku;
    };
    const updateProductConfiguration = (label: string, value: string) => {
      const selectedVariantSku = getProductSkuByConfigurations(label, value);
      const selectedVariantObj = { childSku: selectedVariantSku };

      if (productConfiguration.value[label] === value) return;

      productConfiguration.value[label] = value;
      const routerData = router.resolve({
        path: `${app.localePath(window.location.pathname)}`,
        query: {
          ...selectedVariantObj,
        },
      });
      window.history.pushState({}, null, routerData.href);
      emit('fetchProduct', { query: getBaseSearchQuery() });
    };

    const qtyDisabledStatus = ref(false);
    const shouldDisableCartButtons = computed(
      () => isCartLoading.value
        || !canAddToCart(product.value, qty.value)
        || props.isFetching
        || !isAvailableInStock.value,
    );
    const shouldOptionScroll = (optionCode: string): boolean => {
      const validOptions = ['opisowe_szczegoly_produktu_dropdown'];
      return validOptions.includes(optionCode);
    };
    const isMobileVariantsOpen = ref(false);
    const handleAddItemMobile = ({
      productToAdd,
      quantityToAdd,
      productConfigurationToAdd,
    }) => {
      addItemToCart({
        product: productToAdd,
        quantity: quantityToAdd,
        productConfiguration: productConfigurationToAdd,
        attributes: attributeValues.value,
      });
    };

    const productSchema = computed(() => getProductSchema({
      name: title.value,
      description: productDescription.value,
      sku: currentProduct.value?.sku || '',
      image: getMagentoImageWithConfig(sortedProductGallery.value[0]?.desktop?.url) || '',
      brandName: manufacturerLabel.value,
      deliveryIn: deliveryTimeLabel.value,
      offer: {
        price: finalPrice.value,
        availability: available.value.stock_status,
        url: route.value.fullPath,
        ean: productEan.value,
      },
    }));

    watch(
      () => currentProduct.value,
      () => {
        if (currentProduct.value) {
          qty.value = qtyConfig.value.min_sale_qty;
        }
      },
    );

    const userComPrediction = ref(null);
    const story = ref(null);
    const isMounted = ref(false);
    onMounted(async () => {
      try {
        const userComPredictionResponse = await getUserComPrediction(
          env.VSF_AGRO_USER_COM_URL as string,
        );
        if (userComPredictionResponse) userComPrediction.value = userComPredictionResponse;
      } catch (error) {
        console.log('Error while fetching usercom prediction', error);
      }
      isMounted.value = true;
    });

    const content = ref(null);
    const banners = ref(null);
    const contentBeforePromoBlock = ref(null);
    const mainPromoBlock = ref(null);
    const isNewContent = ref(false);
    const oldDescription = ref(null);
    const isEmptyOldDescription = ref(false);
    const sectionTitle = ref(null);
    const { slug } = root.$route.params;

    function rewriteImages(html: any): string {
      if (!html) return;

      // eslint-disable-next-line consistent-return
      return html.replace(/<a[^>]*>\s*img\s*<\/a>/gi, (value) => value
        .replace(
          '<a',
          '<div class="image-wrapper"><img loading="lazy" alt="Agrosimex" ',
        )
        .replaceAll('http://', '//')
        .replace('href=', 'src=')
        .replace(/>\s*img\s*<\/a>/i, '/></div>'));
    }

    const { fetch } = useFetch(async () => {
      try {
        const { story: data } = await getStoryBySlug({
          slug: `product/${slug}/`,
        });
        if (!data) return;
        story.value = data;
        content.value = data.content;
        banners.value = data.content.banners;
        contentBeforePromoBlock.value = data.content.contentBeforePromoBlock;
        mainPromoBlock.value = data.content.mainPromoBlock;
        sectionTitle.value = data.content.h2_alternative || product.value.name;
        isNewContent.value = content.value?.block?.length > 0;
        oldDescription.value = rewriteImages(
          typeof data.content.old_description === 'string'
            ? data.content.old_description
            : renderRichText(data.content.old_description as ISbStoryData),
        );
        isEmptyOldDescription.value = oldDescription.value === '<p></p>';

        if (!isAuthenticated.value) {
          addTags([{ prefix: CacheTagPrefix.View, value: story.value.full_slug }]);
        }
        if (isMounted.value && story.value && story.value.id) {
          useStoryblokBridge(
            story.value.id,
            (evStory) => {
              story.value = evStory;
            },
            { preventClicks: true },
          );
        }
      } catch (error) {
        console.log('error', error);
      }
    });
    fetch();

    const bundleProductLinksSkus = getBundleProductLinksSkus(product.value);

    const selectVariantAlert = ref(false);
    const setSelectVariantAlert = () => {
      selectVariantAlert.value = true;
    };

    const handleOverlayClick = (value: boolean) => {
      isMobileVariantsOpen.value = value;
    };

    return {
      productMainImage,
      currentProduct,
      title,
      sectionTitle,
      agrofag,
      dosage,
      addItemToCart,
      handleAddItemMobile,
      addItemToWishlist: addOrRemoveItem,
      canAddToCart,
      configurableOptions,
      updateProductConfiguration,
      isAuthenticated,
      isInWishlist: computed(() => isInWishlist({ product: product.value })),
      isCartLoading,
      productConfiguration,
      productShortDescription,
      qty,
      imageSizes,
      isMobileVariantsOpen,
      available,
      deliveryIn,
      deliveryDate,
      addBasePath,
      manufacturerLabel,
      isSpecialPrice,
      activeSubstance,
      purpose,
      grupaUpraw,
      compositionOfFertilizer,
      productDescription,
      productAttachments,
      productEan,
      isNew,
      isVatOnly,
      isRecommended,
      isBestseller,
      isEko,
      finalPrice,
      getIsVariantActive,
      shouldOptionScroll,
      isAvailableInStock,
      shouldDisableCartButtons,
      qtyDisabledStatus,
      isOrderNumber,
      isVariantSelected,
      upsellProductsSKUs,
      relatedProductsSKUs,
      substituteProductsSKUs,
      productSchema,
      currentOmnibusPrice,
      qtyConfig,
      userComPrediction,
      content,
      banners,
      contentBeforePromoBlock,
      mainPromoBlock,
      isNewContent,
      oldDescription,
      isEmptyOldDescription,
      bundleProductLinksSkus,
      baseImageConfig,
      selectVariantAlert,
      setSelectVariantAlert,
      typeOfProtection,
      productAdditionalFields,
      isOutOfStatus,
      sortedProductGallery,
      handleOverlayClick,
      isEstimatedAvailability,
      infoLabel,
    };
  },
  head: {},
});
